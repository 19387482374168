@import url(https://use.typekit.net/mpa1wkh.css);
/* roboto-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-ext-400-normal.493afe7ae8ecfe26880062706f9bc876.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.376ea5d93f71583052f65de4e0c6a92c.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-400-normal.5e493812deabd1d01e6019ad87e77986.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.376ea5d93f71583052f65de4e0c6a92c.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-ext-400-normal.bb723e8458c9c653e50599ab95680430.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.376ea5d93f71583052f65de4e0c6a92c.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-400-normal.1cbfc636c911faa4d0ca77e4b4f90f72.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.376ea5d93f71583052f65de4e0c6a92c.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-vietnamese-400-normal.c0bec65d01f776c00c91a077acb1c2e8.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.376ea5d93f71583052f65de4e0c6a92c.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-ext-400-normal.c3dcdbd5bb4d4af80817dc0edc1cd888.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.376ea5d93f71583052f65de4e0c6a92c.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-400-normal.4673b4537a84c7f7a130799aa6af329b.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.376ea5d93f71583052f65de4e0c6a92c.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* purgecss start ignore */
@tailwind base;
@tailwind components;
html,
body {
	font-family: "acumin-pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	color: rgb(75, 75, 75);
	background: #e0e0e0;
}
/* purgecss end ignore */

@tailwind utilities;

.component-selected {
	position: relative;
}
.component-selected::after {
	content: " ";
	border: 1px dashed #2680eb;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	display: block;
	-webkit-box-sizing: inherit;
	   -moz-box-sizing: inherit;
	        box-sizing: inherit;
}

.transition {
	-webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
	transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.viewport {
	pointer-events: none;
}
.viewport .header {
	top: -100%;
}
.viewport .toolbox {
	left: -100%;
	position: relative;
}
.viewport .sidebar {
	right: -100%;
	position: relative;
}
.viewport .craftjs-renderer {
	opacity: 0;
}

.viewport.mouse-enabled {
	pointer-events: all;
}
.viewport.loaded .header {
	top: 0;
}
.viewport.loaded .toolbox {
	left: 0;
}
.viewport.loaded .sidebar {
	right: 0;
}
.viewport.loaded .craftjs-renderer {
	opacity: 1;
	-webkit-transition-delay: 0.5s;
	        transition-delay: 0.5s;
}

.flex {
	display: -moz-box;
	display: flex;
}
.m-auto {
	margin: auto;
}

