/* purgecss start ignore */
@tailwind base;
@tailwind components;
@import url("https://use.typekit.net/mpa1wkh.css");
html,
body {
	font-family: "acumin-pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	color: rgb(75, 75, 75);
	background: #e0e0e0;
}
/* purgecss end ignore */

@tailwind utilities;

.component-selected {
	position: relative;
}
.component-selected::after {
	content: " ";
	border: 1px dashed #2680eb;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	display: block;
	box-sizing: inherit;
}

.transition {
	transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.viewport {
	pointer-events: none;
}
.viewport .header {
	top: -100%;
}
.viewport .toolbox {
	left: -100%;
	position: relative;
}
.viewport .sidebar {
	right: -100%;
	position: relative;
}
.viewport .craftjs-renderer {
	opacity: 0;
}

.viewport.mouse-enabled {
	pointer-events: all;
}
.viewport.loaded .header {
	top: 0;
}
.viewport.loaded .toolbox {
	left: 0;
}
.viewport.loaded .sidebar {
	right: 0;
}
.viewport.loaded .craftjs-renderer {
	opacity: 1;
	transition-delay: 0.5s;
}

.flex {
	display: flex;
}
.m-auto {
	margin: auto;
}
